import * as React from "react";
import Page from '../../components/Page/page';

const PrivacyPolicy = ({ location }) => {
    return (
        <Page animateHeader location={location} seoTitle="Privacy Policy" title="Privacy Policy">
            <div className="container panel-content py-3">
                <p>Version 1.1. Last Modified: May 18, 2020</p>
                <h4>1. Introduction</h4>
                <p>Privacy of our users is important to us. We do not collect any identifiable information about our users, unless you opt in to use social login. You may also voluntarily provide your email address which may be used for account recovery and marketing. Llama Software uses third party services to store all user data for the purposes of giving our users a better experience, improving gameplay, and allowing access to your account cross-devices. We do not sell nor share any information that is collected within this app.</p>

                <h4>2. Information Collected</h4>
                <p>We collect information about your device, your IP address, general location, how the app is performing on this device, and any information as described in the Social Media section of this policy. This information is used to help provide a better experience to our users, better understand how users interact with our apps and games, and improve gameplay.</p>

                <p>If you choose to voluntarily provide your email address as an account recovery option we will have access to this email and may also occasionally send marketing emails to this email address.</p>

                <h4>3. Third-party services that we use</h4>
                <p>
                    <strong>Unity Ads</strong> � Their privacy policy can be found here: https://unity3d.com/legal/privacy-policy. This is used to serve advertisements within Llama Software apps.<br />
                    <strong>PlayFab</strong> � Their privacy policy can be found here: https://playfab.com/terms/. This is used to provide the account management, leaderboards, and your player stats within the game in a highly available cloud service.<br />
                </p>
                <h4>4. Social Logins</h4>
                <p>If you choose to opt-in to having your account recoverable via a third party Social Login we will receive certain profile information about you from your social media provider. The profile information we receive will include your name, email address, friends list, profile picture, as well as other information you choose to make public. This information is used to provide functionality in the game such as account recovery, friends leaderboards, and synchronizing your name.</p>

                <h4>5. Removal of Data</h4>
                <p>You have the right to opt-out of any data collection by uninstalling the app. If you would like us to remove all data stored about you specifically please contact us by email at support@llama.software.</p>
            </div>
        </Page>
    );
};

export default PrivacyPolicy;